<template>
	<div class="search-bg"
		 v-bind:style="{background: ['white url(' + image + ') center top no-repeat'], 'background-size': ['cover']}">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
					<div class="row mt-4 mb-4">
						<div class="col-12 mt-3 mb-3">
							<form @submit.prevent="submit" class="search-form">
								<div class="alert alert-danger" role="alert" v-if="errors" v-html="errormsg">
								</div>
								<h3>{{ $t('message.form.pickup') }}</h3>
								<div class="input-group mb-2 mt-2"
									 :class="{ 'hasError': $v.form.pickup_location.$error }">
									<div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1"><i
											class="fas fa-map-marker-alt"></i></span>
									</div>
									<select v-bind:placeholder="$t('message.form.s1')+ '*'"
											v-model="form.pickup_location"
											class="form-control">
										<option value="" selected>
											{{ $t('message.form.s1') }} *
										</option>
										<option value="Leiria - Carvoeiros">
											{{ $t('message.form.location1') }}
										</option>
										<option value="Leiria - Zona Industrial">
											{{ $t('message.form.location2') }}
										</option>
										<option value="Caldas da Rainha">
											{{ $t('message.form.location3') }}
										</option>
										<option value="Aeroporto de Lisboa/Porto">
											{{ $t('message.form.location4') }}
										</option>
									</select>
								</div>

								<div class="d-flex justify-content-around mb-3 mt-2">
									<div class="form-group right-square" style="width:60%;margin-bottom:0;"
										 :class="{ 'hasError': $v.form.pickup_date.$error }">
										<datepicker v-bind:placeholder="$t('message.form.s3')+ '*'"
													v-model="form.pickup_date"
													:bootstrap-styling="true"
													:disabled-dates="state.disabledDates"
													name="pickup_date" :language="localeLng"
													:calendar-button="true"
													:calendar-button-icon="'far fa-calendar-alt'"
													@input="handler"
													:highlighted="highlighted"
													class=""></datepicker>
									</div>
									<div class="input-group" style="width:40%"
										 :class="{ 'hasError': $v.form.pickup_time.$error }">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="far fa-clock"></i></span>
										</div>
										<select class="form-control left-square" name="pickup_time"
												v-model="form.pickup_time"
												@change="setHours">
											<option value="" disabled selected>
												{{ $t('message.form.hours') }} *
											</option>
											<option v-for="item in schedules" :value="item">
												{{ item }}
											</option>
										</select>
									</div>
								</div>

								<div class="control-group">
									<label class="control control-checkbox">
										{{ $t('message.form.delivery_change') }}
										<input type="checkbox" checked="checked" v-model="form.delivery_change"
											   value="true"/>
										<div class="control_indicator"></div>
									</label>
								</div>

								<h3 class="mt-3">
									{{ $t('message.form.delivery') }}
								</h3>

								<div class="input-group mb-2 mt-3"
									 v-if="form.delivery_change === true"
									 :class="{ 'hasError': $v.form.delivery_location.$error }">
									<div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon2"><i
											class="fas fa-map-marker-alt"></i></span>
									</div>
									<select v-bind:placeholder="$t('message.form.s2')+ '*'"
											v-model="form.delivery_location"
											class="form-control">
										<option value="" selected>
											{{ $t('message.form.s2') }} *
										</option>
										<option value="Leiria - Carvoeiros">
											{{ $t('message.form.location1') }}
										</option>
										<option value="Leiria - Zona Industrial">
											{{ $t('message.form.location2') }}
										</option>
										<option value="Caldas da Rainha">
											{{ $t('message.form.location3') }}
										</option>
										<option value="Aeroporto de Lisboa/Porto">
											{{ $t('message.form.location4') }}
										</option>
									</select>
								</div>

								<div class="d-flex justify-content-around mb-2 mt-2">
									<div class="form-group right-square" style="width:60%;margin-bottom:0;"
										 :class="{ 'hasError': $v.form.delivery_date.$error }">
										<datepicker v-bind:placeholder="$t('message.form.s4')+ '*'"
													v-model="form.delivery_date"
													:bootstrap-styling="true"
													:calendar-button="true"
													:calendar-button-icon="'far fa-calendar-alt'"
													name="delivery_date"
													:disabled-dates="state.disabledDates2"
													@input="setDate"
													:highlighted="highlighted"
													:language="localeLng"></datepicker>
									</div>
									<div class="input-group" style="width:40%"
										 :class="{ 'hasError': $v.form.delivery_time.$error }">
										<div class="input-group-prepend">
											<span class="input-group-text"><i class="far fa-clock"></i></span>
										</div>
										<select class="form-control left-square" style="width:40%;" name="delivery_time"
												v-model="form.delivery_time">
											<option value="" disabled selected>
												{{ $t('message.form.hours') }} *
											</option>
											<option v-for="item in schedules2" v-bind:value="item">
												{{ item }}
											</option>
										</select>
									</div>
								</div>

								<p class="pt-1">
									* {{ $t('message.form.required') }}
								</p>
								<button class="btn btn-primary btn-block mt-2">
									{{ $t('message.form.find_car') }}
								</button>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {en, ptBR} from 'vuejs-datepicker/dist/locale';
import {required} from 'vuelidate/lib/validators';

let today     = new Date();
let yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate());

export default {
	name       : 'Search',
	components : { Datepicker, },
	data       : function () {
		return {
			times       : ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
			schedules   : ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
			schedules2  : ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
			form        : {
				delivery_location : '',
				delivery_change   : false,
				pickup_location   : '',
				pickup_date       : '',
				pickup_time       : '',
				delivery_date     : '',
				delivery_time     : ''
			},
			highlighted : {
				to   : '',
				from : ''
			},
			state       : {
				disabledDates  : {
					to : yesterday, // Disable all dates up to specific date
				},
				disabledDates2 : {
					to : yesterday
				}
			},
			localeLng   : this.setLocaleLng(),
			lang        : this.$route.params.lang,
			errors      : false,
			errormsg    : '',
			image       : `https://vault13.rotauto.com/sites/Lubrirent/cars/${this.$route.params.lang}/header.jpg`,
		};
	},
	computed   : {
		rules() {
			return {
				pickup_date       : { required },
				pickup_time       : { required },
				delivery_date     : { required },
				delivery_time     : { required },
				pickup_location   : { required },
				delivery_location : this.form.delivery_change === true ? { required } : {},
			};
		}
	},
	watch      : {
		'$route.params.lang' : {
			handler   : function (lang) {
				this.image = `https://vault13.rotauto.com/sites/Lubrirent/cars/${lang}/header.jpg`;
			},
			deep      : true,
			immediate : true
		}
	},
	methods    : {
		handler() {
			this.form.delivery_date = this.$moment(this.form.pickup_date).add(1, 'd').format('YYYY-MM-DD');
			this.setDate();
			this.setTime();
		},
		setTime() {
			let date = new Date(this.form.pickup_date);
			let now  = new Date();
			let time = this.$moment(now).format('HH:mm');
			if (this.$moment(date).format('YYYY-MM-DD') === this.$moment(now).format('YYYY-MM-DD')) {
				let schedules  = this.schedules.filter((e) => {
					return e > time;
				});
				this.schedules = schedules;
			} else {
				this.schedules = this.times;
			}
		},
		setHours() {
			let date         = new Date(this.form.pickup_date);
			let deliveryDate = new Date(this.form.delivery_date);
			let time         = this.form.pickup_time.split(':');
			let hours        = '';
			if (this.$moment(date).format('YYYY-MM-DD') === this.$moment(deliveryDate).format('YYYY-MM-DD')) {
				if (this.form.pickup_time !== '') {
					this.schedules2 = this.schedules2.filter((e) => {
						return e > this.form.pickup_time;
					});
					if (parseInt(time[0]) >= 9 && parseInt(time[0]) < 18) {
						hours = parseInt(time[0]) + 1;
						hours =(hours == 9 ? '0'+hours:hours) + ':00';

					} else {
						hours = '';
					}
					this.form.delivery_time = hours;
				}
			} else {
				this.schedules2 = this.times;
				if (parseInt(time[0]) >= 9 && parseInt(time[0]) <= 18) {
					hours = parseInt(time[0]);
					hours = (hours == 9 ? '0'+hours:hours) + ':00';
				} else {
					hours = '';
				}
				this.form.delivery_time = hours;
			}
		},
		setDate() {
			let date                     = new Date(this.form.pickup_date);
			let deliveryDate             = new Date(this.form.delivery_date);
			this.state.disabledDates2.to = new Date(date.getFullYear(), date.getMonth(), date.getDate());
			this.highlighted.to          = new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate());
			this.highlighted.from        = new Date(date.getFullYear(), date.getMonth(), date.getDate());
			this.setHours();
		},
		setLocaleLng() {
			const lang = this.$route.params.lang;
			if (lang === 'pt') return ptBR;
			else if (lang === 'en') return en;
		},
		submit(e) {
			e.preventDefault();
			this.$v.form.$touch();
			if (this.$v.form.$error) {
				this.errors = true;
				setTimeout(() => {
					this.errors = false;
				}, 10000);
				this.errormsg = this.lang === 'pt' ? 'Preencha os campos obrigatórios.' : 'Required fields are missing.';
				return;
			}
			let vm           = this;
			let deliveryDate = vm.$moment(vm.form.delivery_date).format('DD/MM/YYYY');
			let pickupDate   = vm.$moment(vm.form.pickup_date).format('DD/MM/YYYY');
			if (new Date(pickupDate) > new Date(deliveryDate)) {
				this.errors = true;
				setTimeout(() => {
					this.errors = false;
				}, 10000);
				this.errormsg = this.lang === 'pt' ? 'A data de entrega não pode ser inferior à data de recolha.' : 'Delivery date cannot be lower than pickup date.';
				return;
			}
			if (vm.$gtm.enabled()) {
				window.dataLayer?.push({
					event          : 'form_click',
					category       : 'lead_form',
					action         : 'alugar | Step 1',
					label          : '',
					value          : 0,
					noninteraction : false // Optional
				});
			}


			vm.form.delivery_date = deliveryDate;
			vm.form.pickup_date   = pickupDate;
			this.$router.push({ path : `/${vm.lang}/alugar`, query : vm.form }).catch(err => {
			});
			e.target.reset();
		},
	},
	validations() {
		return {
			form : this.rules
		};
	},
};
</script>
